export class Key {
    constructor(public key: string) {}

    get head() {
        return this.key.split('.')[0];
    }

    get tail() {
        return new Key(this.key.split('.').slice(1).join('.'));
    }
}
