export class SetSuccessResult<T> {
    constructor(public readonly value: T) {}
    public readonly success = true;
}
export abstract class SetFailResult {
    public readonly success = false;
    public abstract readonly errorCode: number;
    public abstract readonly errorMessage: string;
}
export class ElementIsReadOnlySetFailResult extends SetFailResult {
    public readonly errorCode = 403;
    public readonly errorMessage = 'Element is read only';
}
export class ElementNotImplementedSetFailResult extends SetFailResult {
    public readonly errorCode = 401;
    public readonly errorMessage = 'Not implemented';
}
export class IncorrectDataTypeSetFailResult extends SetFailResult {
    public readonly errorCode = 405;
    public readonly errorMessage = 'Incorrect Data Type';
}

export type SetResult<T> = SetSuccessResult<T> | SetFailResult;
