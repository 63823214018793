import { DataModelElementFactory } from '../DataModelElement';
import { GetSuccessResult } from '../DataModelElement/GetResult';
import { SetFailResult, SetSuccessResult } from '../DataModelElement/SetResult';

export const ListElement = <TType>(
    factory: DataModelElementFactory<TType>,
    newItemInitializer: () => TType
): DataModelElementFactory<Array<TType>> => {
    return (nativeValue) => {
        return {
            get(key) {
                if (key.head === '_count') {
                    return new GetSuccessResult(String(nativeValue.length));
                }
                const index = Number(key.head);
                const accessor =
                    nativeValue[index] === undefined
                        ? factory(newItemInitializer())
                        : factory(nativeValue[index]);
                return accessor.get(key.tail);
            },
            set(key, value) {
                const index = Number(key.head);
                const accessor =
                    nativeValue[index] === undefined
                        ? factory(newItemInitializer())
                        : factory(nativeValue[index]);
                const setResult = accessor.set(key.tail, value);
                if (setResult instanceof SetFailResult) {
                    return setResult;
                }
                const newValue = [
                    ...nativeValue.slice(0, index),
                    setResult.value,
                    ...nativeValue.slice(index + 1),
                ];
                return new SetSuccessResult<Array<TType>>(newValue);
            },
        };
    };
};
