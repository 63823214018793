import { useEffect, useMemo } from 'react';
import { WrapperMessage, WrapperMessageParams } from '../../Wrapper/WrapperMessage';

export const useWrapperMessage = ({
    foreignOrigin,
    foreignWindow,
    instanceId,
}: WrapperMessageParams) => {
    const bus = useMemo(() => {
        return new WrapperMessage({ foreignOrigin, foreignWindow, instanceId });
    }, [foreignWindow, foreignOrigin, instanceId]);

    useEffect(() => {
        return bus.listen();
    }, [bus]);

    return bus;
};
