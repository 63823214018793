import { DataModelElementFactory } from '../../DataModelElement';
import { GetSuccessResult } from '../../DataModelElement/GetResult';
import { SetSuccessResult } from '../../DataModelElement/SetResult';

export const StudentName: DataModelElementFactory<{ first_name: string; last_name: string }> = ({
    first_name,
    last_name,
}) => {
    const scormValue = `${last_name}, ${first_name}`;
    return {
        get(_key) {
            return new GetSuccessResult(scormValue);
        },
        set(_key, value) {
            const [lastName, firstName] = value.split(', ');
            return new SetSuccessResult({
                first_name: firstName,
                last_name: lastName,
            });
        },
    };
};
