export class GetSuccessResult {
    constructor(public readonly value: string) {}
    public readonly success = true;
}
export abstract class GetFailResult {
    public readonly success = false;
    public abstract readonly errorCode: number;
    public abstract readonly errorMessage: string;
}
export class ElementNotImplementedGetFailResult extends GetFailResult {
    public readonly errorCode = 401;
    public readonly errorMessage = 'Not implemented';
}
export class ElementIsWriteOnlyGetFailResult extends GetFailResult {
    public readonly errorCode = 404;
    public readonly errorMessage = 'Element is write only';
}

export type GetResult = GetSuccessResult | GetFailResult;
