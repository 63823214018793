import { DataModelElementFactory } from '../../DataModelElement';
import { GetSuccessResult } from '../../DataModelElement/GetResult';
import { IncorrectDataTypeSetFailResult, SetSuccessResult } from '../../DataModelElement/SetResult';

export const CMIBlank: DataModelElementFactory<null> = () => {
    return {
        get(_key) {
            return new GetSuccessResult('');
        },
        set(_key, value) {
            if (value !== '') {
                return new IncorrectDataTypeSetFailResult();
            }
            return new SetSuccessResult(null);
        },
    };
};
