import EventEmitter from 'events';

type MessageDefinition = {
    type: string;
    payload: Record<string, any> | null;
};

type Message<MessageMap> = MessageMap[keyof MessageMap];

export type PostMessageParams = {
    foreignWindow: Window | null;
    foreignOrigin: string;
};

export interface IPostMessage<MessageMap extends Record<string, MessageDefinition>> {
    listen(): void;
    on<TType extends keyof MessageMap>(
        type: TType,
        callback: (payload: MessageMap[TType]['payload']) => unknown
    ): () => void;
    emit<TType extends keyof MessageMap>(type: TType, payload: MessageMap[TType]['payload']): void;
}

export class PostMessage<MessageMap extends Record<string, MessageDefinition>>
    implements IPostMessage<MessageMap>
{
    private readonly foreignWindow: PostMessageParams['foreignWindow'];

    private readonly foreignOrigin: PostMessageParams['foreignOrigin'];

    private readonly emitter: EventEmitter;

    constructor({ foreignWindow, foreignOrigin }: PostMessageParams) {
        this.foreignWindow = foreignWindow;
        this.foreignOrigin = foreignOrigin;

        this.emitter = new EventEmitter();
    }

    private isValidMessage(message: unknown): message is Message<MessageMap> {
        if (typeof message !== 'object') {
            return false;
        }

        if (message === null) {
            return false;
        }

        if ('type' in message && 'payload' in message) {
            if (typeof message.type !== 'string') {
                return false;
            }

            return true;
        }

        return false;
    }

    public listen() {
        const callback = (message: MessageEvent<any>) => {
            if (message.origin !== this.foreignOrigin) {
                return;
            }

            if (this.isValidMessage(message.data) === false) {
                console.warn('Invalid message', message);
                return;
            }

            this.emitter.emit(message.data.type, message.data.payload);
        };

        window.addEventListener('message', callback);

        return () => {
            window.removeEventListener('message', callback);
        };
    }

    public on<TType extends keyof MessageMap>(
        type: TType,
        callback: (payload: MessageMap[TType]['payload']) => unknown
    ): () => void {
        this.emitter.on(String(type), callback);

        return () => {
            this.emitter.removeListener(String(type), callback);
        };
    }

    public emit<TType extends keyof MessageMap>(
        type: TType,
        payload: MessageMap[TType]['payload']
    ) {
        if (!this.foreignWindow) {
            return;
        }

        this.foreignWindow.postMessage(
            {
                type: type,
                payload,
            },
            this.foreignOrigin
        );
    }
}
