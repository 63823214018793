import { CademyError } from '@shared/domain-shared';
import { ScormDataModelData } from '@shared/types';
import { InvalidParameterError } from '../../Wrapper/WrapperError/InvalidParameterError';
import { UnknownErrorCodeError } from '../../Wrapper/WrapperError/UnknownErrorCodeError';
import { WrapperMessage } from '../../Wrapper/WrapperMessage';
import { DataModel } from '../DataModel';
import { GetSuccessResult } from '../DataModel/DataModelElement/GetResult';

type SCORMErrorCode =
    | '0'
    | '101'
    | '201'
    | '202'
    | '203'
    | '301'
    | '401'
    | '402'
    | '403'
    | '404'
    | '405';

export class SCORMAPI {
    private dataModel: DataModel;

    constructor(
        private readonly wrapperMessage: WrapperMessage,
        dataModelData: ScormDataModelData
    ) {
        this.dataModel = DataModel.fromNative(dataModelData);
    }

    private errorCode: string = '0';

    public LMSInitialize(_parameter: ''): 'true' | 'false' {
        try {
            this.wrapperMessage.emit('SCORM:initialize', null);
            this.errorCode = '0';
            return 'true';
        } catch (error) {
            console.error(error);
            this.wrapperMessage.emit('wrapper:error', CademyError.fromUnknown(error));
            return 'false';
        }
    }

    public LMSFinish(_parameter: ''): 'true' | 'false' {
        try {
            this.dataModel.finish();
            const nativeDataModel = this.dataModel.toNative();
            this.wrapperMessage.emit('wrapper:datamodel-updated', nativeDataModel);
            this.wrapperMessage.emit('SCORM:lesson-status-changed', {
                status: nativeDataModel.cmi.core.lesson_status,
            });
            this.wrapperMessage.emit('SCORM:finish', null);
            this.errorCode = '0';
            return 'true';
        } catch (error) {
            console.error(error);
            this.wrapperMessage.emit('wrapper:error', CademyError.fromUnknown(error));
            return 'false';
        }
    }

    public LMSGetValue(element: string): string {
        try {
            if (typeof element !== 'string') {
                throw new InvalidParameterError(
                    `LMSSetValue expected parameter to be a string but got ${typeof element}`
                );
            }

            const getResult = this.dataModel.get(element);
            if (getResult instanceof GetSuccessResult) {
                this.errorCode = '0';
                return getResult.value;
            }
            this.errorCode = String(getResult.errorCode);
            return '';
        } catch (error) {
            console.error(error);
            this.wrapperMessage.emit('wrapper:error', CademyError.fromUnknown(error));
            this.errorCode = '101';
            return '';
        }
    }

    public LMSSetValue(element: string, value: string): 'true' | 'false' {
        try {
            if (typeof element !== 'string') {
                throw new InvalidParameterError(
                    `LMSSetValue expected parameter to be a string but got ${typeof element}`
                );
            }
            if (typeof value !== 'string') {
                throw new InvalidParameterError(
                    `LMSSetValue expected value to be a string but got ${typeof value}`
                );
            }

            const setResult = this.dataModel.set(element, value);
            if (setResult.success === true) {
                this.dataModel = DataModel.fromNative(setResult.value);
                this.wrapperMessage.emit('wrapper:datamodel-updated', setResult.value);

                if (element === 'cmi.core.lesson_status') {
                    this.wrapperMessage.emit('SCORM:lesson-status-changed', {
                        status: setResult.value.cmi.core.lesson_status,
                    });
                }

                this.errorCode = '0';
                return 'true';
            }
            this.errorCode = String(setResult.errorCode);
            return 'false';
        } catch (error) {
            console.error(error);
            this.wrapperMessage.emit('wrapper:error', CademyError.fromUnknown(error));
            this.errorCode = '101';
            return 'false';
        }
    }

    public LMSCommit(parameter: string): 'true' | 'false' {
        this.errorCode = '0';
        return 'true';
    }

    public LMSGetLastError(): string {
        return this.errorCode;
    }

    public LMSGetErrorString(errorNumber: SCORMErrorCode): string {
        switch (errorNumber) {
            case '0':
                return 'No error';
            case '101':
                return 'General exception';
            case '201':
                return 'Invalid argument error';
            case '202':
                return 'Element cannot have children';
            case '203':
                return 'Element not an array - cannot have count';
            case '301':
                return 'Not initialized';
            case '401':
                return 'Not implemented error';
            case '402':
                return 'Invalid set value, element is a keyword';
            case '403':
                return 'Element is read only';
            case '404':
                return 'Element is write only';
            case '405':
                return 'Incorrect Data Type';
            default:
                const error = new UnknownErrorCodeError();
                console.error(error);
                this.wrapperMessage.emit('wrapper:error', CademyError.fromUnknown(error));
                return 'General exception';
        }
    }

    public LMSGetDiagnostic(errorCode: string | number): string {
        try {
            if (typeof errorCode === 'string') {
                const lastErrorCode: string = this.LMSGetLastError();
                const convertedLastErrorCode: SCORMErrorCode = String(
                    lastErrorCode
                ) as SCORMErrorCode;
                return this.LMSGetErrorString(convertedLastErrorCode);
            }
            return this.LMSGetErrorString(String(errorCode) as SCORMErrorCode);
        } catch (error) {
            console.error(error);
            this.wrapperMessage.emit('wrapper:error', CademyError.fromUnknown(error));
            return this.LMSGetErrorString('101');
        }
    }
}
