import { DataModelElementFactory } from '../DataModelElement';
import { GetSuccessResult } from '../DataModelElement/GetResult';

export const VersionedElement = <T>(
    version: string,
    factory: DataModelElementFactory<T>
): DataModelElementFactory<T> => {
    return (nativeValue) => {
        return {
            ...factory(nativeValue),
            get(key) {
                if (key.head === '_version') {
                    return new GetSuccessResult(version);
                }
                return factory(nativeValue).get(key);
            },
        };
    };
};
