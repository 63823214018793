import { ScormDataModelData } from '@shared/types';
import { CMI } from './CMI';
import { GetResult } from './DataModelElement/GetResult';
import { Key } from './DataModelElement/Key';
import { SetResult } from './DataModelElement/SetResult';
import { ObjectElement } from './ObjectElement';

export const SCORMDataModel = ObjectElement({
    cmi: CMI,
});

export class DataModel {
    constructor(private data: ScormDataModelData) {}

    static fromNative(native: ScormDataModelData): DataModel {
        return new DataModel(native);
    }

    get(key: string): GetResult {
        return SCORMDataModel(this.data).get(new Key(key));
    }

    set(key: string, value: string): SetResult<ScormDataModelData> {
        return SCORMDataModel(this.data).set(new Key(key), value);
    }

    toNative(): ScormDataModelData {
        return this.data;
    }

    getFinalLessonStatus(): ScormDataModelData['cmi']['core']['lesson_status'] {
        const masteryScore = this.data.cmi.student_data.mastery_score;
        const actualScore = this.data.cmi.core.score.raw;
        if (masteryScore && actualScore) {
            if (actualScore >= masteryScore) {
                return 'passed';
            } else {
                return 'failed';
            }
        }
        return 'completed';
    }

    finish() {
        this.data = {
            ...this.data,
            cmi: {
                ...this.data.cmi,
                core: {
                    ...this.data.cmi.core,
                    total_time: this.data.cmi.core.total_time + this.data.cmi.core.session_time,
                    session_time: 0,
                    entry: this.data.cmi.core.exit === 'suspend' ? 'resume' : '',
                    lesson_status: this.getFinalLessonStatus(),
                },
            },
        };
    }
}
