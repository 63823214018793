import { DataModelElementFactory } from '../../DataModelElement';
import { GetSuccessResult } from '../../DataModelElement/GetResult';
import { IncorrectDataTypeSetFailResult, SetSuccessResult } from '../../DataModelElement/SetResult';

export const CMISInteger: DataModelElementFactory<number> = (nativeValue) => {
    return {
        get(_key) {
            return new GetSuccessResult(String(Math.floor(nativeValue)));
        },
        set(_key, value) {
            const numericValue = Number(value);
            if (isNaN(numericValue)) {
                return new IncorrectDataTypeSetFailResult();
            }
            if (isFinite(numericValue) === false) {
                return new IncorrectDataTypeSetFailResult();
            }
            return new SetSuccessResult(Math.floor(numericValue));
        },
    };
};
