import { FunctionComponent } from 'react';
import { useSCORMWrapper } from '../../Hooks/useSCORMWrapper';

type SCORMViewerProps = {
    instanceId: string;
    parentOrigin: string;
};

export const SCORMViewer: FunctionComponent<SCORMViewerProps> = ({ instanceId, parentOrigin }) => {
    const { initialized, entryUrl } = useSCORMWrapper({ instanceId, parentOrigin });

    if (!initialized) {
        return null;
    }

    return (
        <iframe
            title="Cademy"
            src={`${process.env.REACT_APP_SCORM_CONTENTS_URL}/contents/${entryUrl}`}
        />
    );
};
