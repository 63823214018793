import { DataModelElementFactory } from '../../DataModelElement';
import { GetSuccessResult } from '../../DataModelElement/GetResult';
import { IncorrectDataTypeSetFailResult, SetSuccessResult } from '../../DataModelElement/SetResult';

export const CMIVocabulary = <TValidVocab extends string>(
    vocabulary: Array<TValidVocab>
): DataModelElementFactory<TValidVocab> => {
    const isValid = (value: string): value is TValidVocab => {
        return vocabulary.includes(value as any) === true;
    };
    return (nativeValue: TValidVocab) => {
        return {
            get(_key) {
                return new GetSuccessResult(nativeValue);
            },
            set(_key, value) {
                if (isValid(value)) {
                    return new SetSuccessResult(value);
                }
                return new IncorrectDataTypeSetFailResult();
            },
        };
    };
};
