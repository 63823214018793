import { DataModelElementFactory } from '../DataModelElement';
import {
    ElementNotImplementedGetFailResult,
    GetSuccessResult,
} from '../DataModelElement/GetResult';
import {
    ElementNotImplementedSetFailResult,
    SetFailResult,
    SetSuccessResult,
} from '../DataModelElement/SetResult';

export const ObjectElement = <TTypeMap extends Record<string, any>>(factories: {
    [Key in keyof TTypeMap]: DataModelElementFactory<TTypeMap[Key]>;
}): DataModelElementFactory<TTypeMap> => {
    return (nativeValue) => {
        return {
            get(key) {
                if (key.head === '_count') {
                    return new GetSuccessResult(String(Object.keys(nativeValue).length));
                }
                if (key.head === '_children') {
                    return new GetSuccessResult(Object.keys(nativeValue).join(','));
                }
                const factory = factories[key.head];
                if (factory === undefined) {
                    return new ElementNotImplementedGetFailResult();
                }
                const accessor = factory(nativeValue[key.head]);
                return accessor.get(key.tail);
            },
            set(key, value) {
                const factory = factories[key.head];
                if (factory === undefined) {
                    return new ElementNotImplementedSetFailResult();
                }
                const accessor = factory(nativeValue[key.head]);
                const setResult = accessor.set(key.tail, value);
                if (setResult instanceof SetFailResult) {
                    return setResult;
                }
                const newValue = {
                    ...nativeValue,
                    [key.head]: setResult.value,
                };
                return new SetSuccessResult(newValue);
            },
        };
    };
};
