import { DataModelElementFactory } from '../../DataModelElement';
import { CMIBlank } from '../CMIBlank';
import { CMIDecimal } from '../CMIDecimal';

export const CMIDecimalOrBlank: DataModelElementFactory<number | null> = (nativeValue) => {
    return {
        get(key) {
            if (nativeValue === null) {
                return CMIBlank(nativeValue).get(key);
            }
            return CMIDecimal(nativeValue).get(key);
        },
        set(key, value) {
            if (value === '') {
                return CMIBlank(null).set(key, value);
            }
            return CMIDecimal(0).set(key, value);
        },
    };
};
