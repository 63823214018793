import { DataModelElementFactory } from '../../DataModelElement';
import { GetSuccessResult } from '../../DataModelElement/GetResult';
import { IncorrectDataTypeSetFailResult, SetSuccessResult } from '../../DataModelElement/SetResult';

const SECOND_IN_MILLISECONDS = 1000;
const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * 60;
const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60;

const millisecondsToTimespanString = (milliseconds: number): string => {
    const hours = Math.floor(milliseconds / HOUR_IN_MILLISECONDS);
    const hoursRemainder = milliseconds % HOUR_IN_MILLISECONDS;
    const minutes = Math.floor(hoursRemainder / MINUTE_IN_MILLISECONDS);
    const minutesRemainder = hoursRemainder % MINUTE_IN_MILLISECONDS;
    const seconds = Math.floor(minutesRemainder / SECOND_IN_MILLISECONDS);
    const secondsRemainder = minutesRemainder % SECOND_IN_MILLISECONDS;

    const stringWithoutFractionalSeconds = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    const fractionalSecond = Math.floor(secondsRemainder / 10);
    if (fractionalSecond === 0) {
        return stringWithoutFractionalSeconds;
    }
    return `${stringWithoutFractionalSeconds}.${fractionalSecond}`;
};

const parseToValidNumber = (string: string): number => {
    const number = Number(string);
    if (isNaN(number)) {
        throw new Error(`Cannot convert "${string}" to number`);
    }
    if (isFinite(number) === false) {
        throw new Error(`Cannot convert "${string}" to number`);
    }
    return number;
};

const timespanStringToMilliseconds = (timespan: string): number => {
    const [hoursString, minutesString, secondsAndMillisecondsString] = timespan.split(':');
    const [secondsString, fractionalSecondsString] = secondsAndMillisecondsString.split('.');

    const hours = parseToValidNumber(hoursString);
    const minutes = parseToValidNumber(minutesString);
    const seconds = parseToValidNumber(secondsString);
    const fractionalSeconds =
        fractionalSecondsString !== undefined ? parseToValidNumber(fractionalSecondsString) : 0;

    return (
        hours * HOUR_IN_MILLISECONDS +
        minutes * MINUTE_IN_MILLISECONDS +
        seconds * SECOND_IN_MILLISECONDS +
        fractionalSeconds * 10
    );
};

export const CMITimespan: DataModelElementFactory<number> = (nativeValue) => {
    const scormValue = millisecondsToTimespanString(nativeValue);
    return {
        get(_key) {
            return new GetSuccessResult(scormValue);
        },
        set(_key, value) {
            try {
                const timespanString = timespanStringToMilliseconds(value);
                return new SetSuccessResult(timespanString);
            } catch (error) {
                console.error(error);
                return new IncorrectDataTypeSetFailResult();
            }
        },
    };
};
