import { useEffect, useMemo, useRef, useState } from 'react';
import { SCORMAPI } from '../../Services/SCORMAPI';
import { useWrapperMessage } from '../useWrapperMessage';

declare global {
    interface Window {
        API?: SCORMAPI;
    }
}

export type UseSCORMWrapperProps = {
    instanceId: string;
    parentOrigin: string;
};

export type UseSCORMWrapperResult =
    | {
          entryUrl: string;
          initialized: true;
      }
    | {
          entryUrl: null;
          initialized: false;
      };

export const useSCORMWrapper = ({
    instanceId,
    parentOrigin,
}: UseSCORMWrapperProps): UseSCORMWrapperResult => {
    const hasLoaded = useRef<boolean>(false);
    const messageBus = useWrapperMessage({
        foreignWindow: window.parent,
        foreignOrigin: parentOrigin,
        instanceId,
    });
    const [entryUrl, setEntryUrl] = useState<string | null>(null);

    useEffect(() => {
        if (hasLoaded.current === true) {
            return;
        }
        messageBus.emit('wrapper:loaded', null);
        hasLoaded.current = true;
    }, [messageBus]);

    useEffect(() => {
        const removeEventListener = messageBus.on('wrapper:initial-data', (message) => {
            window.API = new SCORMAPI(messageBus, message.dataModels);

            setEntryUrl(`${message.token}/${message.scormPackageUrl}`);

            messageBus.emit('wrapper:initialized', null);
        });

        return removeEventListener;
    }, [messageBus]);

    const result = useMemo((): UseSCORMWrapperResult => {
        if (entryUrl === null) {
            return {
                entryUrl: null,
                initialized: false,
            };
        }
        return {
            entryUrl,
            initialized: true,
        };
    }, [entryUrl]);

    return result;
};
