import { ScormDataModelCMI } from '@shared/types';
import { UnInitialized, WriteOnly } from '../../DataModelElement';
import { ListElement } from '../../ListElement';
import { ObjectElement } from '../../ObjectElement';
import { CMIDecimal } from '../CMIDecimal';
import { CMIIdentifier } from '../CMIIdentifier';
import { CMIString } from '../CMIString';
import { CMITime } from '../CMITime';
import { CMITimespan } from '../CMITimespan';
import { CMIVocabulary } from '../CMIVocabulary';

export const CMIInteraction = ObjectElement<ScormDataModelCMI['interactions'][number]>({
    id: WriteOnly(UnInitialized(CMIIdentifier, '')),
    objectives: ListElement(
        ObjectElement({
            id: WriteOnly(CMIIdentifier),
        }),
        () => {
            return { id: '' };
        }
    ),
    time: WriteOnly(UnInitialized(CMITime, 0)),
    type: WriteOnly(
        UnInitialized(
            CMIVocabulary([
                'true-false',
                'choice',
                'fill-in',
                'matching',
                'performance',
                'sequencing',
                'likert',
                'numeric',
            ]),
            'true-false'
        )
    ),
    correct_responses: ListElement(
        ObjectElement({
            pattern: WriteOnly(CMIString),
        }),
        () => {
            return { pattern: '' };
        }
    ),
    weighting: WriteOnly(UnInitialized(CMIDecimal, 0)),
    student_response: WriteOnly(UnInitialized(CMIString, '')),
    result: WriteOnly(UnInitialized(CMIString, '')),
    latency: WriteOnly(UnInitialized(CMITimespan, 0)),
});
