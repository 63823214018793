import { DataModelElementFactory } from '../../DataModelElement';
import { CMIBlank } from '../CMIBlank';
import { CMISInteger } from '../CMISInteger';

export const CMISIntegerOrBlank: DataModelElementFactory<number | null> = (nativeValue) => {
    return {
        get(key) {
            if (nativeValue === null) {
                return CMIBlank(nativeValue).get(key);
            }
            return CMISInteger(nativeValue).get(key);
        },
        set(key, value) {
            if (value === '') {
                return CMIBlank(null).set(key, value);
            }
            return CMISInteger(0).set(key, value);
        },
    };
};
