import { ScormDataModelCMI } from '@shared/types';
import { OptionallyImplemented, ReadOnly, WriteOnly } from '../DataModelElement';
import { ListElement } from '../ListElement';
import { ObjectElement } from '../ObjectElement';
import { VersionedElement } from '../VersionedElement';
import { CMIDecimal } from './CMIDecimal';
import { CMIDecimalOrBlank } from './CMIDecimalOrBlank';
import { CMIIdentifier } from './CMIIdentifier';
import { CMISInteger } from './CMISInteger';
import { CMISIntegerOrBlank } from './CMISIntegerOrBlank';
import { CMIInteraction } from './CMIInteraction';
import { CMIString } from './CMIString';
import { CMITimespan } from './CMITimespan';
import { CMIVocabulary } from './CMIVocabulary';
import { StudentName } from './StudentName';
import { CMIObjective } from './CMIObjective';

export const CMI = VersionedElement(
    '3.4',
    ObjectElement<ScormDataModelCMI>({
        core: ObjectElement({
            student_id: ReadOnly(CMIIdentifier),
            student_name: ReadOnly(StudentName),
            lesson_location: CMIString,
            credit: ReadOnly(CMIVocabulary(['credit', 'no-credit'])),
            lesson_status: CMIVocabulary([
                'passed',
                'completed',
                'failed',
                'incomplete',
                'browsed',
                'not attempted',
            ]),
            lesson_mode: ReadOnly(CMIVocabulary(['browse', 'normal', 'review'])),
            entry: ReadOnly(CMIVocabulary(['ab-initio', 'resume', ''])),
            score: ObjectElement({
                raw: CMIDecimalOrBlank,
                max: CMIDecimalOrBlank,
                min: CMIDecimalOrBlank,
            }),
            total_time: ReadOnly(CMITimespan),
            exit: WriteOnly(CMIVocabulary(['time-out', 'suspend', 'logout', ''])),
            session_time: WriteOnly(CMITimespan),
        }),
        launch_data: CMIString,
        suspend_data: CMIString,
        student_preference: ObjectElement({
            audio: CMISIntegerOrBlank,
            language: CMIString,
            speed: CMISInteger,
            text: CMISInteger,
        }),
        comments: CMIString,
        comments_from_lms: ReadOnly(CMIString),
        student_data: ObjectElement({
            mastery_score: ReadOnly(OptionallyImplemented(CMIDecimal)),
            max_time_allowed: ReadOnly(OptionallyImplemented(CMITimespan)),
            time_limit_action: ReadOnly(
                OptionallyImplemented(
                    CMIVocabulary([
                        'exit,message',
                        'exit,no message',
                        'continue,message',
                        'continue,no message',
                    ])
                )
            ),
        }),
        objectives: ListElement(CMIObjective, () => {
            return {
                id: null,
                score: {
                    raw: null,
                    max: null,
                    min: null,
                },
                status: null,
            };
        }),
        interactions: ListElement(CMIInteraction, () => {
            return {
                id: null,
                objectives: [],
                time: null,
                type: null,
                correct_responses: [],
                weighting: null,
                student_response: null,
                result: null,
                latency: null,
            };
        }),
    })
);
