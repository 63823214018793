import { IPostMessage, PostMessage, PostMessageParams } from '../../Services/PostMessage';
import { SCORMMessageMap } from '@shared/types';

export type WrapperMessageParams = PostMessageParams & { instanceId: string };

type InstanceScopedMessageMap = {
    [Key in keyof SCORMMessageMap]: {
        type: SCORMMessageMap[Key]['type'];
        payload: {
            instanceId: string;
            unscopedPayload: SCORMMessageMap[Key]['payload'];
        };
    };
};

export class WrapperMessage implements IPostMessage<SCORMMessageMap> {
    private readonly instanceId: string;
    private readonly instanceScopedPostMessage: PostMessage<InstanceScopedMessageMap>;

    constructor({ instanceId, ...postMessageParams }: WrapperMessageParams) {
        this.instanceId = instanceId;
        this.instanceScopedPostMessage = new PostMessage(postMessageParams);
    }

    public listen(): () => void {
        return this.instanceScopedPostMessage.listen();
    }

    public on<TType extends keyof SCORMMessageMap>(
        type: TType,
        callback: (payload: SCORMMessageMap[TType]['payload']) => unknown
    ): () => void {
        const blockScopedCallback = (
            payload: InstanceScopedMessageMap[TType]['payload']
        ): unknown => {
            if (payload.instanceId !== this.instanceId) {
                return;
            }
            return callback(payload.unscopedPayload);
        };
        return this.instanceScopedPostMessage.on(type, blockScopedCallback);
    }

    public emit<TType extends keyof SCORMMessageMap>(
        type: TType,
        payload: SCORMMessageMap[TType]['payload']
    ): void {
        return this.instanceScopedPostMessage.emit(type, {
            instanceId: this.instanceId,
            unscopedPayload: payload,
        });
    }
}
