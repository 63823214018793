import { CademyError } from '@shared/domain-shared';

export class WrapperError extends CademyError {
    constructor(
        type: string,
        title: string,
        detail: string | undefined,
        status: number,
        instance?: string,
        extensions?: { [key: string]: any }
    ) {
        super(`SCORMWrapper/${type}`, title, detail, status, instance, extensions);
    }
}
