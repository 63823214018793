import styles from './styles.module.css';
import { SCORMViewer } from './components/SCORMViewer';
import { useMemo } from 'react';

const allowedBaseURLs = ['cademy.co.uk', 'cademy.io', 'cademy.be'];

const allowedParentOrigin = (parentOrigin: string) => {
    try {
        const url = new URL(parentOrigin);

        if (allowedBaseURLs.includes(url.hostname)) {
            return true;
        }

        if (allowedBaseURLs.some((baseURL) => url.hostname.endsWith(`.${baseURL}`))) {
            return true;
        }

        return false;
    } catch (e) {
        console.error(e);
        return false;
    }
};

function App() {
    const { instanceId, parentOrigin } = useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        return {
            instanceId: params.get('instanceId'),
            parentOrigin: params.get('parentOrigin'),
        };
    }, []);

    if (!instanceId) {
        console.error('Missing instanceId from search params');
        return null;
    }

    if (!parentOrigin || !allowedParentOrigin(parentOrigin)) {
        console.error('Missing or disallowed parentOrigin from search params');
        return null;
    }

    return (
        <div className={styles.container}>
            <SCORMViewer instanceId={instanceId} parentOrigin={parentOrigin} />
        </div>
    );
}

export default App;
