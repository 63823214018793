import {
    ElementIsWriteOnlyGetFailResult,
    ElementNotImplementedGetFailResult,
    GetResult,
} from './GetResult';
import { Key } from './Key';
import {
    ElementIsReadOnlySetFailResult,
    ElementNotImplementedSetFailResult,
    SetResult,
} from './SetResult';

export type DataModelElement<NativeType> = {
    get(key: Key): GetResult;
    set(key: Key, value: string): SetResult<NativeType>;
};

export type DataModelElementFactory<NativeType> = (
    nativeType: NativeType
) => DataModelElement<NativeType>;

export type DataModelElementFactoryNativeType<T extends DataModelElementFactory<any>> =
    T extends DataModelElementFactory<infer X> ? X : never;

/**
 * Represents a value that can only be read from
 * @param factory
 * @returns
 */
export const ReadOnly = <T>(factory: DataModelElementFactory<T>): DataModelElementFactory<T> => {
    return (nativeValue: T) => {
        return {
            ...factory(nativeValue),
            set: () => new ElementIsReadOnlySetFailResult(),
        };
    };
};

/**
 * Represents a value that can only be written to
 * @param factory
 * @returns
 */
export const WriteOnly = <T>(factory: DataModelElementFactory<T>): DataModelElementFactory<T> => {
    return (nativeValue: T) => {
        return {
            ...factory(nativeValue),
            get: () => new ElementIsWriteOnlyGetFailResult(),
        };
    };
};

/**
 * Represents a value that can be set but has no defined initial value
 * @param factory
 * @param initialValue A value to give the factory as it's initial value on the first set, generally this can be any valid value as the set will instantly replace it
 */
export const UnInitialized = <T>(
    factory: DataModelElementFactory<T>,
    initialValue: T
): DataModelElementFactory<T | null> => {
    return (nativeValue: T | null) => {
        if (nativeValue === null) {
            return {
                get: () => new ElementNotImplementedGetFailResult(),
                set: (key, value) => {
                    return factory(initialValue).set(key, value);
                },
            };
        }
        return factory(nativeValue);
    };
};

/**
 * Represents a value that is either implemented or not depending on whether it has an initial value
 * @param factory
 * @returns
 */
export const OptionallyImplemented = <T>(
    factory: DataModelElementFactory<T>
): DataModelElementFactory<T | null> => {
    return (nativeValue: T | null) => {
        if (nativeValue === null) {
            return {
                get: () => new ElementNotImplementedGetFailResult(),
                set: () => {
                    return new ElementNotImplementedSetFailResult();
                },
            };
        }
        return factory(nativeValue);
    };
};
