import { ScormDataModelCMI } from '@shared/types';
import { ObjectElement } from '../../ObjectElement';
import { UnInitialized } from '../../DataModelElement';
import { CMIIdentifier } from '../CMIIdentifier';
import { CMIVocabulary } from '../CMIVocabulary';
import { CMIDecimalOrBlank } from '../CMIDecimalOrBlank';

export const CMIObjective = ObjectElement<ScormDataModelCMI['objectives'][number]>({
    id: UnInitialized(CMIIdentifier, ''),
    score: ObjectElement({
        raw: CMIDecimalOrBlank,
        max: CMIDecimalOrBlank,
        min: CMIDecimalOrBlank,
    }),
    status: UnInitialized(
        CMIVocabulary(['passed', 'completed', 'failed', 'incomplete', 'browsed', 'not attempted']),
        'not attempted'
    ),
});
