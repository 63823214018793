import { DataModelElementFactory } from '../../DataModelElement';
import { GetSuccessResult } from '../../DataModelElement/GetResult';
import { SetSuccessResult } from '../../DataModelElement/SetResult';

export const CMIString: DataModelElementFactory<string> = (nativeValue: string) => {
    return {
        get(_key) {
            return new GetSuccessResult(nativeValue);
        },
        set(_key, value) {
            return new SetSuccessResult(value);
        },
    };
};
